import { useIntl } from 'react-intl';
import DateRangeSelector from '../../../components/DateRangeSelector';
import { MultiSelect } from 'react-multi-select-component';
import getMultiSelectStrings from '../../../constants/getMultiSelectStrings';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { listSaleableProducts } from '../../b2b-orders/services/SaleableProductsCRUD';
import {
  SaleableProductModel,
  getProductsPerDistributor,
} from '../../b2b-orders';
import moment from 'moment';

enum StateEnum {
  LOADING,
  IDLE,
}

export default function ReferencesReport() {
  const intl = useIntl();
  const multiSelectStrings = getMultiSelectStrings(intl);
  const [products, setProducts] = useState<SaleableProductModel[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    SaleableProductModel[]
  >([]);
  const [since, setSince] = useState<Date | undefined>(undefined);
  const [until, setUntil] = useState<Date | undefined>(undefined);
  const [state, setState] = useState<StateEnum>(StateEnum.IDLE);
  const [productsPerDistributor, setProductsPerDistributor] = useState<any[]>(
    []
  );

  async function fetchProducts() {
    try {
      setState(StateEnum.LOADING);
      const { data: newProducts } = await listSaleableProducts();
      setProducts(newProducts.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      toast.error(
        intl.formatMessage({ id: 'businessDashboard.productsError' })
      );
      console.warn(error);
    } finally {
      setState(StateEnum.IDLE);
    }
  }

  async function fetchReferences() {
    try {
      setState(StateEnum.LOADING);
      const { data } = await getProductsPerDistributor({
        productIds: selectedProducts.map((product) => product.id),
        since: moment(since).format('YYYY-MM-DD'),
        until: moment(until).format('YYYY-MM-DD'),
      });
      setProductsPerDistributor(data?.Items || []);
    } catch (error) {
      toast.error(
        intl.formatMessage({ id: 'businessDashboard.referencesError' })
      );
      console.warn(error);
    } finally {
      setState(StateEnum.IDLE);
    }
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (since && until && selectedProducts.length > 0) {
      fetchReferences();
    }
  }, [since, until, selectedProducts]);

  return (
    <>
      <h1 className="card-title mb-4">
        {intl.formatMessage({ id: 'businessDashboard.referencesReport' })}
      </h1>
      <div className="d-flex gap-4 align-items-end">
        <DateRangeSelector
          initialSince={
            new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          }
          initialUntil={
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          }
          onSelected={(since, until) => {
            setSince(since);
            setUntil(until);
          }}
        />
        <div className="w-400px mw-600px">
          <label className="form-label">
            {intl.formatMessage({
              id: 'businessDashboard.filterByProductReference',
            })}
          </label>
          <MultiSelect
            className="multi-select"
            overrideStrings={multiSelectStrings}
            labelledBy="Select"
            options={products.map((distributor) => ({
              label: distributor.name,
              value: distributor.id,
            }))}
            value={selectedProducts.map((distributor) => ({
              label: distributor.name,
              value: distributor.id,
            }))}
            onChange={(someProducts: any) => {
              setSelectedProducts(
                someProducts.map((option: any) => {
                  return products.find(
                    (product) => product.id === option.value
                  );
                })
              );
            }}
            valueRenderer={(selectedOptions) => {
              return selectedOptions.length > 0
                ? selectedOptions.map((option) => option.label).join(', ')
                : intl.formatMessage({
                    id: 'businessDashboard.filterByProductReference',
                  });
            }}
          />
        </div>
        {state === StateEnum.LOADING && (
          <span className="spinner-border spinner-border-sm text-primary mb-4"></span>
        )}
      </div>
      {selectedProducts?.map((selectedProduct) => {
        const items = productsPerDistributor?.filter(
          (item) => item.product_id === selectedProduct.id
        );

        return (
          <div className="mt-8" key={selectedProduct.id}>
            <h2 className="mb-4">{selectedProduct.name}</h2>
            {items?.length > 0 ? (
              <table className="table table-hover table-row-dashed table-row-gray-300 table-striped align-middle gs-0 gy-4 mw-650px">
                <thead>
                  <tr className="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                    <th className="ps-4">
                      {intl.formatMessage({
                        id: 'businessDashboard.distributor',
                      })}
                    </th>
                    <th>
                      {intl.formatMessage({
                        id: 'businessDashboard.unitsSold',
                      })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) => (
                    <tr key={item.distributor_id}>
                      <td className="ps-4 mw-400px">
                        <p className="fw-bolder text-gray-700 mb-0">
                          {item.distributor}
                        </p>
                        <p className="mb-0 text-muted">
                          {item.shipping_address}
                        </p>
                      </td>
                      <td className="text-end pe-15">{item.units}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>{intl.formatMessage({ id: 'businessDashboard.noData' })}</p>
            )}
          </div>
        );
      })}
    </>
  );
}
