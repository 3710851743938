import { ShippingAddressModel } from '../models/ShippingAddressModel';
import { BillingAddressModel } from '../models/BillingAddressModel';
import { useIntl } from 'react-intl';
import { FormField, GenericForm } from '../../../components/GenericForm';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Modal } from 'react-bootstrap';
import {
  createDistributorBillingAddress,
  listDistributorBillingAddresses,
  updateDistributorBillingAddress,
} from '../services/BillingAddressCRUD';
import {
  createDistributorShippingAddress,
  listDistributorShippingAddresses,
  updateDistributorShippingAddress,
} from '../services/ShippingAddressCRUD';
import { listShippingServices } from '../../orders/services/OrderCRUD';
import COUNTRIES from '../../../constants/countries';
import COUNTRY_NAMES from '../../../constants/countryNames';

const LOADING_STATUS = 'LOADING_STATUS';
const EDITING_STATUS = 'EDITING_STATUS';
const SAVING_STATUS = 'SAVING_STATUS';
const REMOVING_STATUS = 'REMOVING_STATUS';
const IDLE_STATUS = 'IDLE_STATUS';
const MAX_BILLING_ADDRESSES = 1;

const SHIPPING_SERVICES_WITH_LIMITED_NAME_LENGTH = [
  16, // BRT
  18, // BRT Frío
];
const LIMITED_NAME_LENGTH = 35;
const DEFAULT_NAME_LENGTH = 50;
const NARVAL_STREET_LENGTH = 150;
const NARVAL_SHIPPING_SERVICE = 17;

export default function Addresses({ id }) {
  const intl = useIntl();
  const [status, setStatus] = useState<string>(IDLE_STATUS);
  const [shippingServices, setShippingServices] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState<
    Array<BillingAddressModel>
  >([]);
  const [shippingAddresses, setShippingAddresses] = useState<
    Array<ShippingAddressModel>
  >([]);
  const [shippingAddressErrors, setShippingAddressErrors] = useState<Array<object>>([]);
  const [billingAddressErrors, setBillingAddressErrors] = useState<Array<object>>([]);
  const [editableShippingAddresses, setEditableShippingAddresses] = useState<
    Array<ShippingAddressModel>
  >([]);
  const [shippingAddressStatusses, setShippingAddressStatusses] = useState<
    Array<string>
  >([LOADING_STATUS]);
  const [billingAddressStatusses, setBillingAddressStatusses] = useState<
    Array<string>
  >([LOADING_STATUS]);
  const [visibleShippingAddressModal, setVisibleShippingAddressModal] =
    useState<boolean>(false);
  const [visibleBillingAddressModal, setVisibleBillingAddressModal] =
    useState<boolean>(false);
  const billingAddressesFields: FormField<BillingAddressModel>[] = [
    {
      id: 'name',
      label: intl.formatMessage({ id: 'distributorDetail.nameLabel' }),
      inputType: 'text',
    },
    {
      id: 'vat_number',
      label: intl.formatMessage({ id: 'distributorDetail.vatNumberLabel' }),
      inputType: 'text',
    },
    {
      id: 'street',
      label: intl.formatMessage({ id: 'distributorDetail.streetLabel' }),
      inputType: 'text',
    },
    {
      id: 'postcode',
      label: intl.formatMessage({ id: 'distributorDetail.postcodeLabel' }),
      inputType: 'text',
    },
    {
      id: 'city',
      label: intl.formatMessage({ id: 'distributorDetail.cityLabel' }),
      inputType: 'text',
    },
    /*{
        id: 'region',
        label: intl.formatMessage({ id: 'distributorDetail.regionLabel' }),
        inputType: 'text',
    },*/
    {
      id: 'country',
      label: intl.formatMessage({ id: 'distributorDetail.countryLabel' }),
      inputType: 'select',
      options: COUNTRIES.map((country) => ({
        value: country,
        label: COUNTRY_NAMES[country],
      })),
    },
    {
      id: 'edi_po',
      label: intl.formatMessage({ id: 'distributorDetail.ediPoLabel' }),
      inputType: 'text',
      readonly: true,
    },
  ];
  const initialShippingAddressFormState = {
    distributor: id,
    name: '',
    street: '',
    postcode: '',
    city: '',
    region: '',
    country: 'ES',
    phone: '',
    comments: '',
    shipping_service: null,
  } as ShippingAddressModel;
  const [shippingAddressFormState, setShippingAddressFormState] =
    useState<ShippingAddressModel>(initialShippingAddressFormState);
  const [shippingAddressFormError, setShippingAddressFormError] = useState<object>({});

  const getShippingAddressesFields = (formState) =>
    [
      {
        id: 'country',
        label: intl.formatMessage({ id: 'distributorDetail.countryLabel' }),
        inputType: 'select',
        options: COUNTRIES.map((country) => ({
          value: country,
          label: COUNTRY_NAMES[country],
        })),
      },
      {
        required: true,
        id: 'shipping_service',
        label: intl.formatMessage({ id: 'userProfile.shippingService' }),
        inputType: 'select',
        options: shippingServices?.map((service) => ({
          value: service.id,
          label: service?.name,
        })),
        setter: (
          state: ShippingAddressModel,
          value: string
        ): ShippingAddressModel => {
          return {
            ...state,
            shipping_service: parseInt(value, 10),
          };
        },
      },
      {
        id: 'postcode',
        label: intl.formatMessage({ id: 'distributorDetail.postcodeLabel' }),
        inputType: 'text',
      },
      {
        id: 'city',
        label: intl.formatMessage({ id: 'distributorDetail.cityLabel' }),
        inputType: 'text',
      },
      /*{
            id: 'region',
            label: intl.formatMessage({ id: 'distributorDetail.regionLabel' }),
            inputType: 'text',
    },*/
      {
        id: 'phone',
        label: intl.formatMessage({ id: 'distributorDetail.phoneLabel' }),
        inputType: 'text',
        maxLength: 14,
      },
      {
        id: 'name',
        label: intl.formatMessage({ id: 'distributorDetail.nameLabel' }),
        inputType: 'text',
        maxLength: SHIPPING_SERVICES_WITH_LIMITED_NAME_LENGTH?.includes(
          formState?.shipping_service
        )
          ? LIMITED_NAME_LENGTH
          : formState?.shipping_service === NARVAL_SHIPPING_SERVICE
            ? NARVAL_STREET_LENGTH
            : DEFAULT_NAME_LENGTH,
      },
      {
        id: 'street',
        label: intl.formatMessage({ id: 'distributorDetail.streetLabel' }),
        inputType: 'text',
        maxLength: SHIPPING_SERVICES_WITH_LIMITED_NAME_LENGTH?.includes(
          formState?.shipping_service
        )
          ? LIMITED_NAME_LENGTH
          : formState?.shipping_service === NARVAL_SHIPPING_SERVICE
            ? NARVAL_STREET_LENGTH
            : DEFAULT_NAME_LENGTH,
      },
      {
        id: 'edi_po',
        label: intl.formatMessage({ id: 'distributorDetail.ediPoLabel' }),
        inputType: 'text',
        readonly: true,
      },
      {
        columns: 2,
        id: 'comments',
        label: intl.formatMessage({ id: 'distributorDetail.commentLabel' }),
        inputType: 'textarea',
      },
    ] as FormField<ShippingAddressModel>[];

  function getShippingAddressError(
    shippingAddressState: ShippingAddressModel
  ): object {
    const maxLength = SHIPPING_SERVICES_WITH_LIMITED_NAME_LENGTH?.includes(
      shippingAddressState?.shipping_service
    )
      ? LIMITED_NAME_LENGTH
      : shippingAddressState?.shipping_service === NARVAL_SHIPPING_SERVICE
        ? NARVAL_STREET_LENGTH
        : DEFAULT_NAME_LENGTH;
    const index = editableShippingAddresses.findIndex(
      (address) => address?.id === shippingAddressState?.id
    );
    const errorObject = shippingAddressErrors[index] || {};

    if (shippingAddressState?.name?.length > maxLength) {
      errorObject['name'] = [
        intl.formatMessage(
          { id: 'distributorDetail.nameTooLong' },
          { count: maxLength }
        ),
      ];
    }

    if (shippingAddressState?.street?.length > maxLength) {
      errorObject['street'] = [
        intl.formatMessage(
          { id: 'distributorDetail.streetTooLong' },
          { count: maxLength }
        ),
      ];
    }

    return errorObject;
  }

  async function fetchAddresses(id: number) {
    try {
      const { data: newBillingAddresses } =
        await listDistributorBillingAddresses(id);
      const { data: newShippingAddresses } =
        await listDistributorShippingAddresses(id);
      setBillingAddresses(newBillingAddresses);
      setShippingAddresses(newShippingAddresses);
      setEditableShippingAddresses(newShippingAddresses);
    } catch (e) {
      console.warn(e);
      toast.error(
        intl.formatMessage({
          id: 'distributorDetail.fetchBillingAddressesError',
        })
      );
    }
  }

  async function fetchEverything(id: number) {
    setStatus(LOADING_STATUS);
    await fetchAddresses(id);
    setStatus(IDLE_STATUS);
  }

  useEffect(() => {
    if (id > -1) {
      fetchEverything(id);
    }
  }, [id]);

  useEffect(() => {
    if (billingAddresses.length > 0) {
      setBillingAddressStatusses(billingAddresses.map(() => IDLE_STATUS));
    }
    if (shippingAddresses.length > 0) {
      setShippingAddressStatusses(shippingAddresses.map(() => IDLE_STATUS));
    }
  }, [billingAddresses.length, shippingAddresses.length]);

  function toggleShippingEdit(index) {
    const newStatusses = [...shippingAddressStatusses];
    newStatusses[index] =
      newStatusses[index] === EDITING_STATUS ? IDLE_STATUS : EDITING_STATUS;
    setShippingAddressStatusses(newStatusses);
  }

  function toggleBillingEdit(index: number) {
    const newStatusses = [...billingAddressStatusses];
    newStatusses[index] =
      newStatusses[index] === EDITING_STATUS ? IDLE_STATUS : EDITING_STATUS;
    setBillingAddressStatusses(newStatusses);
  }

  async function saveShippingAddress(
    index: number,
    address: ShippingAddressModel
  ) {
    const newStatusses = [...shippingAddressStatusses];
    newStatusses[index] = SAVING_STATUS;
    setShippingAddressStatusses(newStatusses);

    try {
      await updateDistributorShippingAddress(address);
      await fetchAddresses(id);
      setVisibleShippingAddressModal(false);
      toast.success(
        intl.formatMessage({
          id: 'distributorDetail.saveShippingAddressSuccess',
        })
      );
      const finalStatusses = [...shippingAddressStatusses];
      finalStatusses[index] = IDLE_STATUS;
      setShippingAddressStatusses(finalStatusses);
      setShippingAddressErrors(
        shippingAddressErrors.map((e, i) =>
          i === index ? {} : e
        )
      );
    } catch (error) {
      console.warn(error);

      if (error?.response?.data) {
        setShippingAddressErrors(
          shippingAddressErrors.map((e, i) =>
            i === index ? error.response.data : e
          )
        );
      } else {
        toast.error(
          intl.formatMessage({ id: 'distributorDetail.saveShippingAddressError' })
        );
      }
      const finalStatusses = [...shippingAddressStatusses];
      finalStatusses[index] = EDITING_STATUS;
      setShippingAddressStatusses(finalStatusses);
    }
  }

  async function saveBillingAddress(
    index: number,
    address: BillingAddressModel
  ) {
    const newStatusses = [...billingAddressStatusses];
    newStatusses[index] = SAVING_STATUS;
    setBillingAddressStatusses(newStatusses);

    try {
      await updateDistributorBillingAddress(address);
      await fetchAddresses(id);
      setVisibleBillingAddressModal(false);
      toast.success(
        intl.formatMessage({
          id: 'distributorDetail.saveBillingAddressSuccess',
        })
      );
      const finalStatusses = [...billingAddressStatusses];
      finalStatusses[index] = IDLE_STATUS;
      setBillingAddressStatusses(finalStatusses);
      setBillingAddressErrors(
        billingAddressErrors.map(() => ({}))
      );
    } catch (error) {
      console.warn(error);

      if (error?.response?.data) {
        setBillingAddressErrors(
          billingAddressErrors.map((e, i) =>
            i === index ? error.response.data : e
          )
        );
      } else {
        toast.error(
          intl.formatMessage({ id: 'distributorDetail.saveBillingAddressError' })
        );
      }
      const finalStatusses = [...billingAddressStatusses];
      finalStatusses[index] = EDITING_STATUS;
      setBillingAddressStatusses(finalStatusses);
    } 
  }

  async function removeShippingAddress(
    index: number,
    address: ShippingAddressModel
  ) {
    const confirmed = window.confirm(
      intl.formatMessage({ id: 'distributorDetail.confirmAddressRemoval' })
    );

    if (confirmed) {
      const newStatusses = [...shippingAddressStatusses];
      newStatusses[index] = REMOVING_STATUS;
      setShippingAddressStatusses(newStatusses);

      try {
        setStatus(REMOVING_STATUS);
        await updateDistributorShippingAddress({
          ...address,
          state: 'inactive',
        });
        await fetchAddresses(id);
        toast.success(
          intl.formatMessage({
            id: 'distributorDetail.removeShippingAddressSuccess',
          })
        );
      } catch (e) {
        console.warn(e);
        toast.error(
          intl.formatMessage({
            id: 'distributorDetail.removeBillingAddressError',
          })
        );
      } finally {
        setStatus(IDLE_STATUS);
      }
    }
  }

  async function removeBillingAddress(
    index: number,
    address: BillingAddressModel
  ) {
    const confirmed = window.confirm(
      intl.formatMessage({ id: 'distributorDetail.confirmAddressRemoval' })
    );

    if (confirmed) {
      const newStatusses = [...billingAddressStatusses];
      newStatusses[index] = REMOVING_STATUS;
      setBillingAddressStatusses(newStatusses);

      try {
        setStatus(REMOVING_STATUS);
        await updateDistributorBillingAddress({
          ...address,
          state: 'inactive',
        });
        await fetchAddresses(id);
        toast.success(
          intl.formatMessage({
            id: 'distributorDetail.removeBillingAddressSuccess',
          })
        );
      } catch (e) {
        console.warn(e);
        toast.error(
          intl.formatMessage({
            id: 'distributorDetail.removeBillingAddressError',
          })
        );
      } finally {
        setStatus(IDLE_STATUS);
      }
    }
  }

  function onAddShippingAddress() {
    setVisibleShippingAddressModal(true);
  }

  function onAddBillingAddress() {
    setVisibleBillingAddressModal(true);
  }

  async function addBillingAddress(address: BillingAddressModel) {
    try {
      setStatus(SAVING_STATUS);
      await createDistributorBillingAddress(address);
      await fetchAddresses(id);
      setVisibleBillingAddressModal(false);
      toast.success(
        intl.formatMessage({ id: 'distributorDetail.addBillingAddressSuccess' })
      );
    } catch (e) {
      console.warn(e);
      toast.error(
        intl.formatMessage({ id: 'distributorDetail.addBillingAddressError' })
      );
    } finally {
      setStatus(IDLE_STATUS);
    }
  }

  async function addShippingAddress(address: ShippingAddressModel) {
    try {
      setStatus(SAVING_STATUS);
      setShippingAddressFormError({});
      await createDistributorShippingAddress(address);
      await fetchAddresses(id);
      setVisibleShippingAddressModal(false);
      toast.success(
        intl.formatMessage({
          id: 'distributorDetail.addShippingAddressSuccess',
        })
      );
    } catch (e) {
      console.warn(e);
      if (e?.response?.data) {
        setShippingAddressFormError(e.response.data);
      } else {
        toast.error(
          intl.formatMessage({ id: 'distributorDetail.addShippingAddressError' })
        );
      }
    } finally {
      setStatus(IDLE_STATUS);
    }
  }

  async function fetchShippingServices() {
    try {
      const { data: services } = await listShippingServices();
      if (services) {
        setShippingServices(services);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    if (!shippingServices.length) {
      fetchShippingServices();
    }
  }, [shippingServices]);

  useEffect(() => {
    setShippingAddressErrors(
      editableShippingAddresses.map(() => ({}))
    );
  }, [editableShippingAddresses]);

  useEffect(() => {
    setBillingAddressErrors(
      billingAddresses.map(() => ({}))
    );
  }, [billingAddresses]);

  return (
    <>
      <div className="card p-10">
        <h1 className="mb-8">
          {intl.formatMessage({ id: 'distributorDetail.addresses' })}
        </h1>
        <div className="d-flex mb-8 w-100">
          <Dropdown className="w-100">
            <Dropdown.Toggle
              className="w-100"
              variant="primary"
              id="dropdown-basic"
            >
              <span className="fa fa-plus me-4 "></span>
              {intl.formatMessage({ id: 'distributorDetail.addAddress' })}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {billingAddresses?.length < MAX_BILLING_ADDRESSES && (
                <Dropdown.Item
                  className="py-3 ps-5 fs-6"
                  onClick={onAddBillingAddress}
                >
                  {intl.formatMessage({
                    id: 'distributorDetail.addBillingAddress',
                  })}
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={onAddShippingAddress}
                className="py-3 ps-5 fs-6"
              >
                {intl.formatMessage({
                  id: 'distributorDetail.addShippingAddress',
                })}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="">
          {billingAddresses?.length > 0 ? (
            <section>
              {billingAddresses.map(
                (billingAddress: BillingAddressModel, index) => (
                  <article className="mb-3" key={billingAddress.id}>
                    <GenericForm
                      columns={2}
                      title={intl.formatMessage({
                        id: 'distributorDetail.billing',
                      })}
                      fields={billingAddressesFields}
                      initialState={billingAddress}
                      ctaLabel={intl.formatMessage({
                        id: 'distributorDetail.saveChanges',
                      })}
                      onSubmit={(address: BillingAddressModel) =>
                        saveBillingAddress(index, address)
                      }
                      toggleEdit={() => toggleBillingEdit(index)}
                      editing={
                        billingAddressStatusses[index] === EDITING_STATUS
                      }
                      submitting={
                        billingAddressStatusses[index] === SAVING_STATUS
                      }
                      submittingLabel={intl.formatMessage({
                        id: 'distributorDetail.saving',
                      })}
                      error={billingAddressErrors[index]}
                    >
                      {{
                        afterForm: (
                          <button
                            className="btn btn-tertiary d-inline-block"
                            onClick={() =>
                              removeBillingAddress(index, billingAddress)
                            }
                            disabled={
                              billingAddressStatusses[index] === REMOVING_STATUS
                            }
                          >
                            <span className="indicator-label">
                              <span className="fas fa-trash me-4"></span>
                              {intl.formatMessage({
                                id: 'distributorDetail.remove',
                              })}
                            </span>
                            <span className="indicator-progress">
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          </button>
                        ),
                      }}
                    </GenericForm>
                  </article>
                )
              )}
            </section>
          ) : (
            <>
              <p>
                {intl.formatMessage({
                  id: 'distributorDetail.noBillingAddresses',
                })}
              </p>
            </>
          )}
          {shippingAddresses?.length > 0 &&
          editableShippingAddresses.length > 0 ? (
            <section>
              {shippingAddresses.map(
                (shippingAddress: ShippingAddressModel, index) => {
                  const editableShippingAddress =
                    editableShippingAddresses[index];

                  return (
                    <article className="mb-3" key={shippingAddress.id}>
                      <GenericForm
                        columns={2}
                        title={intl.formatMessage({
                          id: 'distributorDetail.shipping',
                        })}
                        fields={getShippingAddressesFields(
                          editableShippingAddress
                        )}
                        ctaLabel={intl.formatMessage({
                          id: 'distributorDetail.saveChanges',
                        })}
                        onSubmit={(shippingAddress) =>
                          saveShippingAddress(index, shippingAddress)
                        }
                        toggleEdit={() => toggleShippingEdit(index)}
                        editing={[EDITING_STATUS, SAVING_STATUS].includes(
                          shippingAddressStatusses[index]
                        )}
                        submitting={
                          shippingAddressStatusses[index] === SAVING_STATUS
                        }
                        submittingLabel={intl.formatMessage({
                          id: 'distributorDetail.saving',
                        })}
                        initialState={shippingAddress}
                        state={editableShippingAddress}
                        setState={(state) =>
                          setEditableShippingAddresses(
                            editableShippingAddresses.map((address, i) =>
                              i === index ? state : address
                            )
                          ) as any
                        }
                        error={getShippingAddressError(editableShippingAddress)}
                        disabled={
                          Object.keys(
                            getShippingAddressError(editableShippingAddress)
                          ).length > 0
                        }
                      >
                        {{
                          afterForm: (
                            <button
                              className="btn btn-tertiary d-inline-block"
                              onClick={() =>
                                removeShippingAddress(index, shippingAddress)
                              }
                              disabled={
                                shippingAddressStatusses[index] ===
                                REMOVING_STATUS
                              }
                            >
                              <span className="indicator-label">
                                <span className="fas fa-trash me-4"></span>
                                {intl.formatMessage({
                                  id: 'distributorDetail.remove',
                                })}
                              </span>
                              <span className="indicator-progress">
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            </button>
                          ),
                        }}
                      </GenericForm>
                    </article>
                  );
                }
              )}
            </section>
          ) : (
            <>
              <p>
                {intl.formatMessage({
                  id: 'distributorDetail.noShippingAddresses',
                })}
              </p>
            </>
          )}
        </div>
      </div>
      <Modal
        show={visibleShippingAddressModal}
        centered
        onHide={() => setVisibleShippingAddressModal(false)}
      >
        <Modal.Body>
          <GenericForm
            editable={false}
            columns={2}
            title={intl.formatMessage({
              id: 'distributorDetail.addShippingAddress',
            })}
            fields={getShippingAddressesFields(shippingAddressFormState)}
            ctaLabel={intl.formatMessage({ id: 'distributorDetail.add' })}
            onSubmit={(shippingAddress) => addShippingAddress(shippingAddress)}
            toggleEdit={() => {}}
            editing={true}
            submitting={status === 'SAVING_STATUS'}
            submittingLabel={intl.formatMessage({
              id: 'distributorDetail.saving',
            })}
            initialState={initialShippingAddressFormState}
            state={shippingAddressFormState}
            setState={setShippingAddressFormState as any}
            error={{
              ...getShippingAddressError(shippingAddressFormState),
              ...shippingAddressFormError
            }}
            disabled={
              Object.keys(getShippingAddressError(shippingAddressFormState))
                .length > 0
            }
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={visibleBillingAddressModal}
        centered
        onHide={() => setVisibleBillingAddressModal(false)}
      >
        <Modal.Body>
          <GenericForm
            editable={false}
            columns={2}
            title={intl.formatMessage({
              id: 'distributorDetail.addBillingAddress',
            })}
            fields={billingAddressesFields}
            initialState={
              {
                distributor: id,
                name: '',
                vat_number: '',
                street: '',
                city: '',
                postcode: '',
                country: 'ES',
                region: '',
              } as BillingAddressModel
            }
            ctaLabel={intl.formatMessage({ id: 'distributorDetail.add' })}
            onSubmit={(billingAddress) => addBillingAddress(billingAddress)}
            toggleEdit={() => {}}
            editing={true}
            submitting={status === 'SAVING_STATUS'}
            submittingLabel={intl.formatMessage({
              id: 'distributorDetail.saving',
            })}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}