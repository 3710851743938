import axios from 'axios';
import { SaleableProductModel } from '../models/SaleableProductModel';

const API_URL = process.env.REACT_APP_API_URL;
const SALEABLE_PRODUCTS_URL = `${API_URL}/api/b2b/saleable-products/`;

export async function listSaleableProducts(
  distributorId?: string
): Promise<{ data: Array<SaleableProductModel> }> {
  if (!distributorId) return await axios.get(SALEABLE_PRODUCTS_URL);

  return await axios.get(SALEABLE_PRODUCTS_URL, {
    params: {
      distributor: distributorId,
    },
  });
}

interface ProductsPerDistributorRequest {
  since: string;
  until: string;
  productIds: Array<number>;
}

interface ProductsPerDistributorItem {
  product_id: number;
  product: string;
  distributor: string;
  shipping_address: string;
  units: number;
}

interface ProductsPerDistributorResponse {
  Items: Array<ProductsPerDistributorItem>;
}

const PRODUCTS_PER_DISTRIBUTOR_URL = `${API_URL}/api/b2b/shipping-addresses/get-product-distributor/`;

export async function getProductsPerDistributor({
  since,
  until,
  productIds,
}: ProductsPerDistributorRequest): Promise<{
  data: ProductsPerDistributorResponse;
}> {
  const params = {
    since_data: since,
    until_data: until,
    product_ids: productIds.join(','),
  };
  return await axios.get(PRODUCTS_PER_DISTRIBUTOR_URL, { params });
}
