export enum PaymentMethodEnum {
  WIRE_TRANSFER = 'wire-transfer',
  BANK_DRAFT = 'bank-draft',
}

export interface DistributorModel {
  id: number;
  name: string;
  vat_number: string;
  email: string;
  phone: string;
  state: string;
  taxes: Array<number>;
  payment_method?: PaymentMethodEnum;
  max_payment_days?: number;
  special_payment_due_date?: number;
  is_non_payer?: boolean;
  files?: Array<{
    id: number;
    file_url: string;
    file_name: string;
    uploaded_at: string;
    distributor: number;
  }>;
  total_discounts: number;
  discount_on: 'order' | 'order_line';
  discount_mode: 'absolute' | 'relative';
}
