import axios from 'axios';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL;
const INFLUENCERS_URL = `${API_URL}/api/influencers/`;

export function generateReport({ since, until}) {
  const endpoint = `${INFLUENCERS_URL}stats/?start_date=${
    moment(since).format('YYYY-MM-DD')
  }&end_date=${
    moment(until).format('YYYY-MM-DD')
  }`;

  return axios.get(endpoint);
}
