import { Modal } from 'react-bootstrap';
import { OrderModel } from '../models/OrderModel';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { SaleableProductModel } from '../models/SaleableProductModel';
import Select from 'react-select';

interface PropsType {
  order: OrderModel | undefined;
  products: Array<SaleableProductModel>;
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onAddLine: (line) => void;
  onRemoveLine: (line) => void;
}

export default function ModalOrderForm({
  order,
  products,
  visible,
  onAddLine,
  onRemoveLine,
  onClose,
}: PropsType) {
  const intl = useIntl();
  const [orderState, setOrderState] = useState<OrderModel | undefined>(order);
  const [selectedProduct, setSelectedProduct] =
    useState<SaleableProductModel | null>(null);
  const [units, setUnits] = useState<number>(1);
  const [isFree, setIsFree] = useState<boolean>(false);
  const productOptions = [
    {
      value: '',
      label: intl.formatMessage({ id: 'b2bOrders.selectProduct' }),
      disabled: false,
    },
    ...(getProducts()?.map((product) => ({
      value: product.id,
      label: `${product.name} - ${product.ean} - ${
        product.pvf_unit
          ? `${intl.formatNumber(product.pvf_unit, {
              style: 'currency',
              currency: 'EUR',
            })}`
          : ''
      }`,
      disabled: typeof product?.pvf_unit !== 'number',
    })) || []),
  ];

  useEffect(() => {
    setOrderState(order);
  }, [order]);

  const handleAddLine = () => {
    if (selectedProduct && units > 0) {
      const line = {
        product: selectedProduct.id,
        units,
        order: order?.id,
        is_free: isFree,
      };
      onAddLine(line);
    }
    setSelectedProduct(null);
    setUnits(1);
    setIsFree(false);
  };

  function getProducts() {
    const productsWithUnitPrice = products?.filter(
      (product) => product.pvf_unit
    );
    const productsWithoutUnitPrice = products?.filter(
      (product) => !product.pvf_unit
    );

    return [
      ...(productsWithUnitPrice?.sort((a, b) => a.name.localeCompare(b.name)) ||
        []),
      ...(productsWithoutUnitPrice?.sort((a, b) =>
        a.name.localeCompare(b.name)
      ) || []),
    ];
  }

  return (
    <Modal show={visible} onClose={onClose} size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {intl.formatMessage(
              { id: 'b2bOrders.orderLines' },
              { code: order?.code }
            )}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="mb-8 w-100">
            <div className="row align-items-end">
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label">
                    {intl.formatMessage({ id: 'b2bOrders.product' })}
                  </label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={productOptions}
                    value={
                      productOptions.find(
                        (option) => option.value === selectedProduct?.id
                      ) || null
                    }
                    isOptionDisabled={(option) => option.disabled}
                    onChange={(option) => {
                      const product = products.find(
                        (product) => product.id === option.value
                      );
                      setSelectedProduct(product || null);
                    }}
                    placeholder={intl.formatMessage({
                      id: 'orderDetail.searchProductPlaceholder',
                    })}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label className="form-label">
                    {intl.formatMessage({ id: 'b2bOrders.units' })}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={units}
                    min={1}
                    onChange={(e) => setUnits(parseInt(e.target.value, 10))}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="form-label mb-3">
                    {intl.formatMessage({ id: 'b2bOrders.isFree' })}
                  </label>
                  <div className="form-check form-switch mb-3 h-30px d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={isFree}
                      onChange={(e) => setIsFree(e.target.checked)}
                    />
                    <label
                      className="form-check-label ms-3"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      {isFree
                        ? intl.formatMessage({ id: 'b2bOrders.yes' })
                        : intl.formatMessage({ id: 'b2bOrders.no' })}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddLine}
                  disabled={!selectedProduct || units <= 0}
                >
                  <span className="fa fa-plus me-2" />
                  {intl.formatMessage({ id: 'b2bOrders.addLine' })}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {orderState?.order_lines?.length === 0 && (
                <p>{intl.formatMessage({ id: 'b2bOrders.noLines' })}</p>
              )}
              {orderState?.order_lines?.length > 0 && (
                <table className="table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 mx-auto">
                  <thead className="table-header">
                    <tr>
                      <th>{intl.formatMessage({ id: 'b2bOrders.product' })}</th>
                      <th>{intl.formatMessage({ id: 'b2bOrders.units' })}</th>
                      <th>
                        {intl.formatMessage({ id: 'b2bOrders.unitPrice' })}
                      </th>
                      <th>
                        {intl.formatMessage({ id: 'b2bOrders.totalPrice' })}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderState?.order_lines
                      ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                      ?.map((line, index) => (
                        <tr key={index}>
                          <td className="text-wrap px-1">
                            {line?.name}

                          </td>
                          <td>{line.units}</td>
                          <td>
                            {intl.formatNumber(line.unit_price_wo_taxes, {
                              style: 'currency',
                              currency: 'EUR',
                            })}
                          </td>
                          <td>
                            {intl.formatNumber(line.total_price_wo_taxes, {
                              style: 'currency',
                              currency: 'EUR',
                            })}
                          </td>
                          <td className="text-start px-0">
                            <button
                              onClick={() => onRemoveLine(line)}
                              type="button"
                              className="btn btn-sm fa fa-trash text-danger m-0 p-0 me-4"
                            />
                          </td>
                        </tr>
                      ))}
                    <tr className="text-start fw-bolder ">
                      <td colSpan={2} className="bg-white"></td>
                      <td colSpan={1} className="text-primary">
                        {intl.formatMessage({
                          id: 'b2bOrders.totalProductsWithoutTaxes',
                        })}
                      </td>
                      <td colSpan={2} className="text-primary">
                        {intl.formatNumber(orderState?.total_wo_taxes, {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              <button
                type="button"
                className="btn btn-primary mt-8 ms-auto d-block"
                onClick={onClose}
              >
                {intl.formatMessage({ id: 'b2bOrders.accept' })}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
