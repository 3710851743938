import clsx from 'clsx';
import { FC, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dropdown } from 'react-bootstrap';

import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu } from './HeaderUserMenu';
import { useLayout } from '../../core';
import { AddTaskModal } from '../../../../app/modules/tasks/components/AddTaskModal';
import * as taskRedux from '../../../../app/modules/tasks/redux/TaskRedux';
import * as authRedux from '../../../../app/modules/auth/redux/AuthRedux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './alarm.css';
import {
  ACQUISITION_GROUP_ID,
  listTaskCounts,
} from '../../../../app/modules/tasks';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const ALARM_RETRIEVAL_INTERVAL = 1000 * 60 * 20; // 20 minutes

const Topbar: FC = () => {
  const { config } = useLayout();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [visibleAddTaskModal, setVisibleAddTaskModal] = useState(false);
  const alarmTasks = useSelector(taskRedux.alarmTasksSelector);
  const clientTasks = useSelector(taskRedux.clientTasksSelector);
  const router = useHistory();
  const [taskCounts, setTaskCounts] = useState([]);
  const authUser = useSelector(authRedux.authUserSelector);
  const isAcquisitionAgent = authUser?.cs_groups?.find(
    (group) => group.id === ACQUISITION_GROUP_ID
  );
  const giantCount = taskCounts?.find((count) => count.name === 'Lead campaña giant')
    ?.n || 0;
  const pendingCount =
    taskCounts?.find((count) => count.name === 'Pendientes')?.n || 0;
  const possibleCount =
    taskCounts?.find((count) => count.name === 'Posible')?.n || 0;
  const pendingCustomerCount =
    taskCounts?.find((count) => count.name === 'Pendiente de cliente')?.n || 0;
  const recoveryCount =
    taskCounts?.find((count) => count.name === 'New rescate')?.n || 0;
  const notReachableCount =
    taskCounts?.find((count) => count.name === 'No contactado')?.n || 0;
  const noAnswerCount =
    taskCounts?.find((count) => count.name === 'No contesta a teléfono')?.n ||
    0;
  const unknownCount =
    taskCounts?.find((count) => count.name === 'Llamada perdida')?.n || 0;
  const newLeadCount =
    taskCounts?.find((count) => count.name === 'Nuevo lead')?.n || 0;
  const noRecipesCount =
    taskCounts?.find((count) => count.name === 'Lead sin recetas')?.n || 0;
  const leadCallRequestCount =
    taskCounts?.find((count) => count.name === 'Solicitud de llamada de lead')
      ?.n || 0;
  const alarmCount = useSelector(taskRedux.alarmTasksCountSelector);
  const totalTasks = useSelector(taskRedux.pendingTaskSelector)?.total_tasks;
  const toastId = useRef(null);

  async function retrieveAllTasks() {
    const { data: counts } = await listTaskCounts();
    setTaskCounts(counts);
    dispatch(taskRedux.actions.retrieveAlarmTasks());
  }

  useEffect(() => {
    retrieveAllTasks();
  }, []);

  let alarmInterval = null;
  useEffect(() => {
    if (router.location.pathname === '/alarms') {
      clearInterval(alarmInterval);
      alarmInterval = null;
      return;
    }
    if (!alarmInterval) {
      alarmInterval = setInterval(() => {
        dispatch(taskRedux.actions.retrieveAlarmTasks());
      }, ALARM_RETRIEVAL_INTERVAL);
    }
    return () => {
      clearInterval(alarmInterval);
      alarmInterval = null;
    };
  }, [router.location.pathname, alarmInterval]);

  useEffect(() => {
    const alarmTask = alarmTasks
      ?.filter((alarmTask) => alarmTask.state !== 'solved')
      ?.find((alarmTask) => {
        const dispatchAfter = new Date(alarmTask.dispatch_after);
        const now = new Date();
        return dispatchAfter.getTime() <= now.getTime();
      });
    const isCarrot = alarmTask?.task_profile?.name === 'Zanahoria';

    if (
      router.location.pathname === '/alarms' ||
      (alarmTask?.id &&
        router.location.pathname.includes(`/task/${alarmTask?.id}`)) ||
      (alarmTask?.id &&
        clientTasks?.find((task) => task.id === alarmTask?.id)?.state ===
          'solved') ||
      alarmTask?.state === 'solved' ||
      !alarmTask
    ) {
      if (toastId?.current) {
        toast.dismiss(toastId?.current);
        toastId.current = null;
      }
      return;
    }

    if (alarmTask && !toastId?.current) {
      toastId.current = toast(
        <span className="fs-6 text-dark">
          {isCarrot ? (
            <span className="fs-4 me-2">🥕</span>
          ) : (
            <span className="fa fa-bell me-2" />
          )}
          <strong>
            {moment(alarmTask.dispatch_after).format('HH:mm')} -{' '}
            {intl.formatMessage({ id: 'tasks.scheduledAlarm' })}
          </strong>
          <br />
          {alarmTask?.task_profile?.name}:{' '}
          {alarmTask?.user?.name ||
            alarmTask?.user?.email ||
            alarmTask?.email ||
            alarmTask?.phone ||
            '-'}
        </span>,
        {
          position: 'bottom-left',
          className: clsx('toastify-container alarm', isCarrot ? 'carrot' : ''),
          autoClose: false,
          onClick: () =>
            alarmTask?.user?.id
              ? router.push(`/user/${alarmTask.user.id}/task/${alarmTask.id}`)
              : router.push(`/task/${alarmTask.id}`),
        }
      );
    }
  }, [
    alarmTasks,
    router.location.pathname,
    clientTasks,
    toastId?.current,
    moment().format('mm'),
  ]);

  function onMyTasksClick() {
    retrieveAllTasks();
  }

  function onMyNextTaskClick(e) {
    e.preventDefault();
    dispatch(taskRedux.actions.dispatchTask(null, null));
  }

  return (
    <>
      <div className="d-flex align-items-stretch flex-shrink-0">
        <div
          className={clsx(
            'd-flex align-items-center justify-content-start',
            toolbarButtonMarginClass
          )}
        >
          <button
            onClick={() => {
              setVisibleAddTaskModal(true);
            }}
            className="btn btn-tertiary d-flex align-items-center me-2 p-0"
          >
            <span className="fas fa-phone-slash me-3 text-muted" />
            <span className="text-muted">
              {intl.formatMessage({ id: 'app.reportMissedCall' })}
            </span>
          </button>
          <Dropdown>
            <Dropdown.Toggle variant="tertiary" className="text-muted">
              <span className="fas fa-list me-3 text-muted" />
              <span onClick={onMyTasksClick}>
                {intl.formatMessage({ id: 'app.myTasks' })}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => router.push('/agenda')}
                className="py-3 ps-5 fs-6"
              >
                <span className="badge me-2 px-2 py-1 badge-secondary">
                  {pendingCount}
                </span>
                {intl.formatMessage({ id: 'app.pending' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/possible')}
                className="py-3 ps-5 fs-6"
              >
                <span className="badge me-2 px-2 py-1 badge-secondary">
                  {possibleCount}
                </span>
                {intl.formatMessage({ id: 'app.possible' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/pending-customer')}
                className="py-3 ps-5 fs-6"
              >
                <span className="badge me-2 px-2 py-1 badge-secondary">
                  {pendingCustomerCount}
                </span>
                {intl.formatMessage({ id: 'app.pendingCustomer' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/recovery')}
                className="py-3 ps-5 fs-6"
              >
                <span className="badge me-2 px-2 py-1 badge-secondary">
                  {recoveryCount}
                </span>
                {intl.formatMessage({ id: 'app.recovery' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/not-reachable')}
                className="py-3 ps-5 fs-6"
              >
                <span className="badge me-2 px-2 py-1 badge-secondary">
                  {notReachableCount}
                </span>
                {intl.formatMessage({ id: 'app.notReachable' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/no-answer')}
                className="py-3 ps-5 fs-6"
              >
                <span className="badge me-2 px-2 py-1 badge-secondary">
                  {noAnswerCount}
                </span>
                {intl.formatMessage({ id: 'app.noAnswer' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/unknown')}
                className="py-3 ps-5 fs-6"
              >
                <span className="badge me-2 px-2 py-1 badge-secondary">
                  {unknownCount}
                </span>
                {intl.formatMessage({ id: 'app.unknown' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/new-leads')}
                className="py-3 ps-5 fs-6"
              >
                <span
                  className={clsx(
                    'badge me-2 px-2 py-1 badge-secondary',
                    isAcquisitionAgent && 'badge-warning text-dark'
                  )}
                >
                  {newLeadCount}
                </span>
                {intl.formatMessage({ id: 'app.newLeads' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/no-recipes')}
                className="py-3 ps-5 fs-6"
              >
                <span
                  className={clsx(
                    'badge me-2 px-2 py-1 badge-secondary',
                    isAcquisitionAgent && 'badge-warning text-dark'
                  )}
                >
                  {noRecipesCount}
                </span>
                {intl.formatMessage({ id: 'app.noRecipes' })}
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => router.push('/giant-tasks')}
                className="py-3 ps-5 fs-6"
              >
                <span
                  className={clsx(
                    'badge me-2 px-2 py-1 badge-secondary',
                    isAcquisitionAgent && 'badge-warning text-dark'
                  )}
                >
                  {giantCount}
                </span>
                {intl.formatMessage({ id: 'app.giantTasks' })}
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => router.push('/lead-call-requests')}
                className="py-3 ps-5 fs-6"
              >
                <span
                  className={clsx(
                    'badge me-2 px-2 py-1 badge-secondary',
                    isAcquisitionAgent && 'badge-warning text-dark'
                  )}
                >
                  {leadCallRequestCount}
                </span>
                {intl.formatMessage({ id: 'app.leadCallRequests' })}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => router.push('/alarms')}
                className="py-3 ps-5 fs-6"
              >
                <span className={'badge me-2 px-2 py-1 badge-secondary'}>
                  {alarmCount}
                </span>
                {intl.formatMessage({ id: 'app.alarms' })}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button
            onClick={onMyNextTaskClick}
            id="kt_activities_toggle"
            className="btn btn-tertiary text-muted py-3 ps-5 fs-6"
          >
            <span className="me-4 fas fa-inbox text-muted"></span>
            {intl.formatMessage({ id: 'app.myNextTask' })}
            <span className="badge me-2 px-2 py-1 badge-secondary ms-2">
              {totalTasks}
            </span>
          </button>
        </div>
        <div
          className={clsx(
            'd-flex align-items-center',
            toolbarButtonMarginClass
          )}
          id="kt_header_user_menu_toggle"
        >
          <div
            className={clsx(
              'cursor-pointer symbol',
              toolbarUserAvatarHeightClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <img
              src={toAbsoluteUrl('/media/avatars/300-1.jpg')}
              alt="metronic"
            />
          </div>
          <HeaderUserMenu />
        </div>
        {config.header.left === 'menu' && (
          <div
            className="d-flex align-items-center d-lg-none ms-4 me-n3"
            title="Show header menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_header_menu_mobile_toggle"
            >
              <KTSVG
                path="/media/icons/duotune/text/txt001.svg"
                className="svg-icon-1"
              />
            </div>
          </div>
        )}
      </div>
      <AddTaskModal
        onHide={() => setVisibleAddTaskModal(false)}
        show={visibleAddTaskModal}
        reportMissedCall={true}
        setShowModal={setVisibleAddTaskModal}
      />
    </>
  );
};

export { Topbar };
