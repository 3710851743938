import axios from 'axios';

import BusinessDashboardModel from '../models/BusinessDashboardModel';
import KpiDetailModel from '../models/KpiDetailModel';
import { OrderStateEnum } from '../../orders';
import { Source } from '../../tasks/models/TaskSetResolutionModel';

const API_URL = process.env.REACT_APP_API_URL;

export function getBusinessDashboard({
  since = null,
  until = null,
}): Promise<{ data: BusinessDashboardModel }> {
  const endpoint = `${API_URL}/api/bi/dashboard/`;
  const params = {
    since_data: since,
    until_data: until,
  };
  return axios.get(endpoint, { params });
}

interface Props {
  since: string;
  until: string;
  agent?: number;
  kind?: 'rescate' | 'retention' | 'subscription' | 'trials';
}

export function getAtcDashboard({
  since = null,
  until = null,
  agent = null,
  kind = null,
}: Props): Promise<{ data: BusinessDashboardModel }> {
  const endpoint = `${API_URL}/api/bi/dashboard-atc/`;
  const params = {
    since_data: since,
    until_data: until,
  };
  if (agent) {
    params['agent'] = agent;
  }
  if (kind) {
    params['kind'] = kind;
  }
  return axios.get(endpoint, { params });
}

export function getKpiDetails({
  id,
}): Promise<{ data: Array<KpiDetailModel> }> {
  const endpoint = `${API_URL}/api/bi/${id}/details/`;

  return axios.get(endpoint);
}

export function sendSalesExcel({ since, until }) {
  const endpoint = `${API_URL}/api/bi/send-sales-excel/`;
  const params = {
    promise_date__gte: since,
    promise_date__lte: until,
  };

  return axios.post(endpoint, params);
}

export function sendUserKpiReport({ since, until }) {
  const endpoint = `${API_URL}/api/b2c/user-kpis/`;
  const params = {
    start_date: since,
    end_date: until,
  };

  return axios.get(endpoint, { params });
}

interface SendOrdersExcelProps {
  since: string;
  until: string;
  states: Array<OrderStateEnum>;
}
export function sendOrdersExcel({
  since,
  until,
  states,
}: SendOrdersExcelProps) {
  const endpoint = `${API_URL}/api/bi/send-orders-excel/`;
  const params = {
    promise_date__gte: since,
    promise_date__lte: until,
    states,
  };

  return axios.post(endpoint, params);
}

export interface SaleOrderSegmentationRequest {
  promise_date__lte?: string;
  promise_date__gte?: string;
  charge_date__lte?: string;
  charge_date__gte?: string;
  state?: Array<string>;
  total_price_inc_tax__lte?: number;
  total_price_inc_tax__gte?: number;
  gross_margin_percentage__lte?: number;
  gross_margin_percentage__gte?: number;
  postcode?: Array<string>;
}

export interface PetSegmentationRequest {
  breed?: Array<number>;
  body_condition: Array<number>;
  gender?: Array<string>;
  age_years__lte?: number;
  age_years__gte?: number;
  age_months__lte?: number;
  age_months__gte?: number;
  weight_in_kg__lte?: number;
  weight_in_kg__gte?: number;
  is_neutered?: boolean;
  activity_level?: Array<number>;
  current_food_type?: Array<number>;
  allergies?: Array<number>;
  health_issues?: Array<number>;
}

export interface PlanSegmentationRequest {
  created_at__lte?: string;
  created_at__gte?: string;
  meal_kind?: Array<'original' | 'special-care'>;
  kind?: Array<'custom' | 'auto'>;
  state?: Array<string>;
}

export interface UserSegmentationRequest {
  status?: Array<'proposal' | 'active' | 'paused'>;
  date_joined__gte?: string;
  date_joined__lte?: string;
  date_first_order__gte?: string;
  date_first_order__lte?: string;
  total_spent__gte?: number;
  total_spent__lte?: number;
  total_orders__gte?: number;
  total_orders__lte?: number;
  is_from_competitor?: boolean;
  source?: Source;
  competitor?: string;
}

export interface TaskSegmentationRequest {
  profile?: Array<number>;
  group?: Array<number>;
  agent?: Array<number>;
  resolution?: Array<number>;
  created_at__gte?: string;
  created_at__lte?: string;
  dispatch_after__gte?: string;
  dispatch_after__lte?: string;
  resolution_changed_at__gte?: string;
  resolution_changed_at__lte?: string;
}

export interface SegmentCalculationRequest {
  plan?: PlanSegmentationRequest;
  sale_order?: SaleOrderSegmentationRequest;
  pet?: PetSegmentationRequest;
  user?: UserSegmentationRequest;
  store?: Array<number>;
  pet_kind?: Array<number>;
  task?: TaskSegmentationRequest;
}

export interface SegmentCalculationResponse {
  count: number;
  next: string;
  previous: string;
  results: Array<{
    id: number;
    code: string;
  }>;
}

const cleanRequest = (obj) => {
  if (
    obj?.sale_order?.postcode?.length > 0 &&
    !Array.isArray(obj.sale_order.postcode)
  ) {
    obj.sale_order.postcode = obj.sale_order.postcode
      .split(',')
      .map((postcode) => postcode.trim())
      .filter(Boolean);
  }

  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      cleanRequest(obj[key]);
    }
    if (
      obj[key] === undefined ||
      obj[key] === null ||
      obj[key] === '' ||
      (Array.isArray(obj[key]) && obj[key].length === 0)
    )
      delete obj[key];
  });

  if (typeof obj?.user?.competitor === 'string') {
    obj.user.competitor = obj.user.competitor.split(',');
  }
  if (typeof obj?.user?.source === 'string') {
    obj.user.source = obj.user.source.split(',');
  }

};
export function calculateSegment(
  segmentRequest: SegmentCalculationRequest
): Promise<{ data: SegmentCalculationResponse }> {
  const endpoint = `${API_URL}/api/bi/segments/`;
  const cleanSegmentRequest = JSON.parse(JSON.stringify(segmentRequest));

  cleanRequest(cleanSegmentRequest);

  return axios.post(endpoint, cleanSegmentRequest);
}

export function generateSegmentedReport(
  segmentRequest: SegmentCalculationRequest
): Promise<{ data: SegmentCalculationResponse }> {
  const endpoint = `${API_URL}/api/bi/segments/`;
  let cleanSegmentRequest = JSON.parse(JSON.stringify(segmentRequest));

  cleanRequest(cleanSegmentRequest);

  return axios.post(endpoint, {
    ...cleanSegmentRequest,
    download_csv: true,
  });
}

interface GetLeadKpisRequest {
  start_date: string;
  end_date: string;
  agent?: number;
}

export interface LeadKpis {
  leads_touched_data: {
    leads_touched: number;
    leads_not_touched: number;
    leads_touched_percentage: number;
    leads_not_touched_percentage: number;
  };
  task_lead_attempts: {
    [key: string]: number;
  };
  ko_total_data: {
    ko_total: number;
    'ko_total_call-requested-lead': number;
    'ko_total_quiz-completed-and-no-results': number;
    'ko_total_new-lead': number;
    'ko_total_call-requested-lead_percentage': number;
    'ko_total_quiz-completed-and-no-results_percentage': number;
    'ko_total_new-lead_percentage': number;
  };
  leads_converted_data: {
    leads_converted: number;
    leads_converted_percentage: number;
  };
  leads_received_data: number;
}

export function getLeadKpis({
  start_date,
  end_date,
  agent,
}: GetLeadKpisRequest): Promise<{ data: LeadKpis }> {
  const endpoint = `${API_URL}/api/bi/kpi-leads/`;
  const params = {
    start_date,
    end_date,
    agent,
  };
  return axios.get(endpoint, { params });
}

interface ListTopSalesProps {
  since: string;
  until: string;
  distributors?: Array<number>;
}

export interface SaleItem {
  product_id: number;
  product: string;
  total_units: number;
  total_price_wo_taxes: number;
}
interface ListTopSalesResponse {
  Items: Array<SaleItem>;
}

export function listTopSales({
  since,
  until,
  distributors,
}: ListTopSalesProps): Promise<{ data: ListTopSalesResponse }> {
  const endpoint = `${API_URL}/api/b2b/orders/top-sales/`;
  const params = {
    since_data: since,
    until_data: until,
    distributor: distributors.join(','),
  };

  return axios.get(endpoint, { params });
}

export function listEstimatedSalesByRecipe({
  since,
  until,
}: ListTopSalesProps): Promise<{
  data: { [key: string]: number };
}> {
  const endpoint = `${API_URL}/api/bi/estimated-sales-by-recipe/`;
  const params = {
    start_date: since,
    end_date: until,
  };

  return axios.get(endpoint, { params });
}
