import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GenericForm, FormField } from '../../../components/GenericForm';
import { toast } from 'react-toastify';
import {
  DistributorModel,
  PaymentMethodEnum,
} from '../models/DistributorModel';
import {
  retrieveDistributor,
  updateDistributor,
} from '../services/DistributorCRUD';
import { listTaxes } from '../services/TaxCRUD';
import { staffUsersSelector } from '../../tasks';
import { useSelector } from 'react-redux';

const LOADING_STATUS = 'LOADING_STATUS';
const IDLE_STATUS = 'IDLE_STATUS';

export default function DistributorDetail({ id }) {
  const intl = useIntl();
  const [status, setStatus] = useState(IDLE_STATUS);
  const [error, setError]: any | undefined = useState({});
  const [editing, setEditing] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [distributor, setDistributor] = useState<DistributorModel | null>(null);
  const staffUsers = useSelector(staffUsersSelector);
  
  const fetchDistributor = async (id) => {
    try {
      const { data } = await retrieveDistributor(id);
      setDistributor(data);
      const { data: taxesData } = await listTaxes();
      setTaxes(taxesData);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    fetchDistributor(id);
  }, [id]);

  async function onSubmit(formState: DistributorModel) {
    try {
      setStatus(LOADING_STATUS);
      const { data } = await updateDistributor(formState);
      setDistributor(data);
      setEditing(false);
      toast.success(intl.formatMessage({ id: 'distributorDetail.saved' }));
    } catch (error) {
      console.warn(error);
      if (error.response?.data) {
        setError(error.response?.data);
      }
      toast.error(intl.formatMessage({ id: 'distributorDetail.errorSaving' }));
    } finally {
      setStatus(IDLE_STATUS);
    }
  }

  const formFields: Array<FormField<DistributorModel>> = [
    {
      id: 'name',
      label: intl.formatMessage({ id: 'distributors.name' }),
      inputType: 'text',
    },
    {
      id: 'vat_number',
      label: intl.formatMessage({ id: 'distributors.vatNumber' }),
      inputType: 'text',
    },
    {
      id: 'email',
      label: intl.formatMessage({ id: 'distributors.email' }),
      inputType: 'email',
    },
    {
      id: 'phone',
      label: intl.formatMessage({ id: 'distributors.phone' }),
      inputType: 'text',
      maxLength: 14,
    },
    {
      id: 'payment_method',
      label: intl.formatMessage({ id: 'distributors.paymentMethod' }),
      inputType: 'select',
      options: [
        {
          value: PaymentMethodEnum.WIRE_TRANSFER,
          label: intl.formatMessage({ id: 'distributors.wireTransfer' }),
        },
        {
          value: PaymentMethodEnum.BANK_DRAFT,
          label: intl.formatMessage({ id: 'distributors.bankDraft' }),
        },
      ],
    },
    {
      id: 'max_payment_days',
      label: intl.formatMessage({ id: 'distributors.maxPaymentDays' }),
      inputType: 'number',
    },
    {
      id: 'special_payment_due_date',
      label: intl.formatMessage({ id: 'distributors.specialPaymentDueDate' }),
      inputType: 'number',
      min: 1,
      max: 31,
    },
    {
      id: 'is_non_payer',
      label: intl.formatMessage({ id: 'distributors.isNonPayer' }),
      inputType: 'checkbox',
    },
    {
      columns: 2,
      id: 'taxes',
      label: intl.formatMessage({ id: 'distributors.taxes' }),
      inputType: 'multiple',
      options: taxes?.map((tax) => ({
        value: tax.id.toString(),
        label: `${tax.name} ${tax.tax_class.name} ${tax.country} ${
          tax.vat > 0 && `(${Math.trunc(tax.vat * 100)}%)`
        }`,
      })),
      getter: (state: DistributorModel) => {
        const newValue = state?.taxes?.map((tax) => tax.toString()).join(',');

        return newValue;
      },
      setter: (state: DistributorModel, value: string): DistributorModel => {
        const newTaxesValue = value
          .split(',')
          .filter((token) => !!token.length)
          .map((tax) => parseInt(tax));

        return {
          ...state,
          taxes: newTaxesValue,
        };
      },
    },
    {
      id: 'discount_mode',
      label: intl.formatMessage({ id: 'distributors.discountMode' }),
      inputType: 'select',
      options: [
        {
          value: 'absolute',
          label: intl.formatMessage({ id: 'distributors.absolute' }),
        },
        {
          value: 'relative',
          label: intl.formatMessage({ id: 'distributors.relative' }),
        },
      ],
    },
    {
      id: 'total_discounts',
      label: intl.formatMessage({ id: 'distributors.totalDiscounts' }),
      inputType: 'number',
      step: 0.01,
    },
    {
      id: 'discount_on',
      label: intl.formatMessage({ id: 'distributors.discountOn' }),
      inputType: 'select',
      options: [
        {
          value: 'order',
          label: intl.formatMessage({ id: 'distributors.order' }),
        },
        {
          value: 'order_line',
          label: intl.formatMessage({ id: 'distributors.orderLine' }),
        },
      ],
    },
    {
      id: 'agent',
      label: intl.formatMessage({ id: 'distributors.agent' }),
      inputType: 'select',
      options: staffUsers?.map((user) => ({
        value: user.id,
        label: user.name,
      })) || [],
    }
  ];

  return (
    <div className="card p-10">
      {status === 'loading_request_user' ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <>
          {distributor ? (
            <>
              <GenericForm
                columns={2}
                error={error}
                editing={editing}
                toggleEdit={() => setEditing(!editing)}
                title={intl.formatMessage({
                  id: 'distributorDetail.infoTitle',
                })}
                initialState={distributor}
                fields={formFields}
                ctaLabel={intl.formatMessage({
                  id: 'distributorDetail.saveChanges',
                })}
                onSubmit={onSubmit}
                submitting={status === LOADING_STATUS}
                submittingLabel={intl.formatMessage({
                  id: 'distributorDetail.pleaseWait',
                })}
              />
            </>
          ) : (
            <p>
              {intl.formatMessage({ id: 'distributorDetail.noDistributor' })}
            </p>
          )}
        </>
      )}
    </div>
  );
}
