import { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_metronic/layout/core';
import { LeadDistributorReport } from '../../modules/lead-distributors';
import TopSalesReport from '../../modules/dashboard/components/TopSalesReport';
import ReferencesReport from '../../modules/dashboard/components/ReferencesReport';

const B2BReportPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'leadDistributorReport.title' })}
      </PageTitle>
      <div className="row gy-4">
        <div className="card p-10">
          <TopSalesReport />
        </div>
        <div className="card p-10">
          <ReferencesReport />
        </div>
        <div className="card p-10">
          <LeadDistributorReport />
        </div>
      </div>
    </>
  );
};

export { B2BReportPage };
