import { TaskCommentModel } from '../models/TaskCommentModel';
import { TaskSetResolutionModel } from '../models/TaskSetResolutionModel';
import { TaskModel } from '../models/TaskModel';
import { TaskGroupModel } from '../models/TaskGroupModel';
import { UserModel } from '../../auth/models/UserModel';
import { TaskStatus } from './TaskStatusType';
import { PendingsModel } from '../models/PendingsModel';
import { TaskListModel } from '../models/TaskListModel';

export const actionTypes = {
  SetPendingTaskFilters: 'TASKS_SET_PENDING_TASK_FILTERS',
  SetPendingTask: 'TASK_REDUX_SET_PENDING_TASK',
  SetPendingTasks: 'TASK_REDUX_SET_PENDING_TASKS',
  SetSelectedTask: 'TASK_REDUX_SET_SELECTED_TASK',
  SetNotReachableExpiredTasks: 'TASK_REDUX_SET_NOT_REACHABLE_EXPIRED_TASKS',
  SetPossibleTasks: 'TASK_REDUX_SET_POSSIBLE_TASKS',
  SetPendingCustomerTasks: 'TASK_REDUX_SET_PENDING_CUSTOMER_TASKS',
  SetAlarmTasks: 'TASK_REDUX_SET_ALERT_TASKS',
  SetGroups: 'TASK_REDUX_SET_GROUPS',
  SetStaffUsers: 'TASK_REDUX_SET_STAFF_USERS',
  SetPendingTaskError: 'TASK_REDUX_SET_PENDING_TASK_ERROR',
  SetSelectedTaskError: 'TASK_REDUX_SET_SELECTED_TASK_ERROR',
  SetPendingTaskStatus: 'TASK_REDUX_SET_PENDING_TASK_STATUS',
  SetPendingTasksStatus: 'TASK_REDUX_SET_PENDING_TASKS_STATUS',
  SetPossibleTasksStatus: 'TASK_REDUX_SET_POSSIBLE_TASKS_STATUS',
  SetPendingCustomerTasksStatus: 'TASK_REDUX_SET_PENDING_CUSTOMER_TASKS_STATUS',
  SetNotReachableExpiredTasksStatus:
    'TASK_REDUX_SET_NOT_REACHABLE_EXPIRED_TASKS_STATUS',
  SetAlarmTasksStatus: 'TASK_REDUX_SET_ALERT_TASKS_STATUS',
  SetSelectedTaskStatus: 'TASK_REDUX_SET_SELECTED_TASK_STATUS',
  DispatchTask: 'TASK_REDUX_DISPATCH',
  CommentPendingTask: 'TASK_REDUX_COMMENT_PENDING_TASK',
  CommentSelectedTask: 'TASK_REDUX_COMMENT_SELECTED_TASK',
  ResolvePendingTask: 'TASK_REDUX_RESOLVE_PENDING_TASK',
  ResolveSelectedTask: 'TASK_REDUX_RESOLVE_SELECTED_TASK',
  RetrieveNotReachableExpiredTasks:
    'TASK_REDUX_RETRIEVE_NOT_REACHABLE_EXPIRED_TASKS',
  RetrievePossibleTasks: 'TASK_REDUX_RETRIEVE_POSSIBLE_TASKS',
  RetrievePendingCustomerTasks: 'TASK_REDUX_RETRIEVE_PENDING_CUSTOMER_TASKS',
  RetrieveAlarmTasks: 'TASK_REDUX_RETRIEVE_ALERT_TASKS',
  listGroups: 'TASK_REDUX_RETRIEVE_GROUPS',
  RetrieveStaffUsers: 'TASK_REDUX_RETRIEVE_STAFF_USERS',
  RequestTask: 'TASK_REDUX_REQUEST_TASK',
  RetrievePendingTasks: 'TASK_REDUX_RETRIEVE_PENDING_TASKS',
  UpdatePendingTask: 'TASK_REDUX_UPDATE_PENDING_TASK',
  SetPendingsPerAgent: 'TASK_REDUX_SET_PENDINGS_PER_AGENT',
  RetrievePendingsPerAgent: 'TASK_REDUX_RETRIEVE_PENDINGS_PER_AGENT',
  SetPendingsPerAgentStatus: 'TASK_REDUX_SET_PENDINGS_PER_AGENT_STATUS',
  RetrievePendingAgentTasks: 'TASK_REDUX_RETRIEVE_PENDING_AGENT_TASKS',
  SetPendingAgentTasks: 'TASK_REDUX_SET_PENDING_AGENT_TASKS',
  SetPendingAgentTasksStatus: 'TASK_REDUX_SET_PENDING_AGENT_TASKS_STATUS',
  RetrieveRecoveryTasks: 'TASK_REDUX_RETRIEVE_RECOVERY_TASKS',
  SetRecoveryTasks: 'TASK_REDUX_SET_RECOVERY_TASKS',
  SetRecoveryTasksStatus: 'TASK_REDUX_SET_RECOVERY_TASKS_STATUS',
  SetUnknownTasks: 'TASK_REDUX_SET_UNKNWON_TASKS',
  SetUnknownTasksStatus: 'TASK_REDUX_SET_UNKNWON_TASKS_STATUS',
  RetrieveUnknownTasks: 'TASK_REDUX_RETRIEVE_UNKNWON_TASKS',
  RetrieveNewLeadTasks: 'TASK_REDUX_RETRIEVE_NEW_LEAD_TASKS',
  RetrieveNoRecipesTasks: 'TASK_REDUX_RETRIEVE_NO_RECIPES_TASKS',
  RetrieveLeadCallRequestTasks: 'TASK_REDUX_RETRIEVE_LEAD_CALL_REQUEST_TASKS',
  SetNewLeadTasks: 'TASK_REDUX_SET_NEW_LEAD_TASKS',
  SetNewLeadTasksStatus: 'TASK_REDUX_SET_NEW_LEAD_TASKS_STATUS',
  SetNoRecipesTasks: 'TASK_REDUX_SET_NO_RECIPES_TASKS',
  SetNoRecipesTasksStatus: 'TASK_REDUX_SET_NO_RECIPES_TASKS_STATUS',
  SetLeadCallRequestTasks: 'TASK_REDUX_SET_LEAD_CALL_REQUEST_TASKS',
  SetLeadCallRequestTasksStatus:
    'TASK_REDUX_SET_LEAD_CALL_REQUEST_TASKS_STATUS',
  RetrieveNoAnswerTasks: 'TASK_REDUX_RETRIEVE_NO_ANSWER_TASKS',
  SetNoAnswerTasks: 'TASK_REDUX_SET_NO_ANSWER_TASKS',
  SetNoAnswerTasksStatus: 'TASK_REDUX_SET_NO_ANSWER_TASKS_STATUS',
  RetrieveClientTasks: 'TASK_REDUX_RETRIEVE_CLIENT_TASKS',
  SetClientTasks: 'TASK_REDUX_SET_CLIENT_TASKS',
  SetClientTasksStatus: 'TASK_REDUX_SET_CLIENT_TASKS_STATUS',
  RetrieveGiantTasks: 'TASK_REDUX_RETRIEVE_GIANT_TASKS',
  SetGiantTasks: 'TASK_REDUX_SET_GIANT_TASKS',
  SetGiantTasksStatus: 'TASK_REDUX_SET_GIANT_TASKS_STATUS',
};

export const actions = {
  retrieveGiantTasks: (page?: number) => ({
    type: actionTypes.RetrieveGiantTasks,
    payload: { page },
  }),
  setGiantTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetGiantTasks,
    payload: { tasks },
  }),
  setGiantTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetGiantTasksStatus,
    payload: { status },
  }),
  retrieveClientTasks: (id: number, page?: number) => ({
    type: actionTypes.RetrieveClientTasks,
    payload: { page, id },
  }),
  setClientTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetClientTasks,
    payload: { tasks },
  }),
  retrieveNoAnswerTasks: (page?: number) => ({
    type: actionTypes.RetrieveNoAnswerTasks,
    payload: { page },
  }),
  setNoAnswerTasks: (tasks) => ({
    type: actionTypes.SetNoAnswerTasks,
    payload: { tasks },
  }),
  setNoAnswerTasksStatus: (status) => ({
    type: actionTypes.SetNoAnswerTasksStatus,
    payload: { status },
  }),
  setPendingTaskFilters: ({ taskResolution, taskProfile }) => ({
    type: actionTypes.SetPendingTaskFilters,
    payload: {
      taskResolution,
      taskProfile,
    },
  }),
  setUnknownTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetUnknownTasks,
    payload: { tasks },
  }),
  setUnknownTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetUnknownTasksStatus,
    payload: { status },
  }),
  retrievePendingsPerAgent: (ownerId?: number) => ({
    type: actionTypes.RetrievePendingsPerAgent,
    payload: { ownerId },
  }),
  retrieveUnknownTasks: (page?: number) => ({
    type: actionTypes.RetrieveUnknownTasks,
    payload: { page },
  }),
  setPendingTask: (task: TaskModel) => ({
    type: actionTypes.SetPendingTask,
    payload: { task },
  }),
  setPendingTasks: (tasks: Array<TaskModel>) => ({
    type: actionTypes.SetPendingTasks,
    payload: { tasks },
  }),
  setSelectedTask: (task: TaskModel) => ({
    type: actionTypes.SetSelectedTask,
    payload: { task },
  }),
  setNotReachableExpiredTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetNotReachableExpiredTasks,
    payload: { tasks },
  }),
  setPossibleTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetPossibleTasks,
    payload: { tasks },
  }),
  setPendingCustomerTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetPendingCustomerTasks,
    payload: { tasks },
  }),
  setAlarmTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetAlarmTasks,
    payload: { tasks },
  }),
  retrieveNotReachableExpiredTasks: (page?: number) => ({
    type: actionTypes.RetrieveNotReachableExpiredTasks,
    payload: { page },
  }),
  retrievePossibleTasks: (page?: number) => ({
    type: actionTypes.RetrievePossibleTasks,
    payload: { page },
  }),
  retrieveAlarmTasks: (page?: number) => ({
    type: actionTypes.RetrieveAlarmTasks,
    payload: { page },
  }),
  retrievePendingTasks: (since?: string, until?: string) => ({
    type: actionTypes.RetrievePendingTasks,
    payload: { since, until },
  }),
  retrievePendingCustomerTasks: (page?: number) => ({
    type: actionTypes.RetrievePendingCustomerTasks,
    payload: { page },
  }),
  retrieveRecoveryTasks: (page?: number) => ({
    type: actionTypes.RetrieveRecoveryTasks,
    payload: { page },
  }),
  setRecoveryTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetRecoveryTasks,
    payload: { tasks },
  }),
  retrieveNewLeadTasks: (page?: number) => ({
    type: actionTypes.RetrieveNewLeadTasks,
    payload: { page },
  }),
  setNewLeadTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetNewLeadTasks,
    payload: { tasks },
  }),
  setNewLeadTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetNewLeadTasksStatus,
    payload: { status },
  }),
  retrieveNoRecipesTasks: (page?: number) => ({
    type: actionTypes.RetrieveNoRecipesTasks,
    payload: { page },
  }),
  setNoRecipesTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetNoRecipesTasks,
    payload: { tasks },
  }),
  setNoRecipesTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetNoRecipesTasksStatus,
    payload: { status },
  }),
  retrieveLeadCallRequestTasks: (page?: number) => ({
    type: actionTypes.RetrieveLeadCallRequestTasks,
    payload: { page },
  }),
  setLeadCallRequestTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetLeadCallRequestTasks,
    payload: { tasks },
  }),
  setLeadCallRequestTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetLeadCallRequestTasksStatus,
    payload: { status },
  }),
  setRecoveryTasksStatus: (status: string) => ({
    type: actionTypes.SetRecoveryTasksStatus,
    payload: { status },
  }),
  retrievePendingAgentTasks: ({
    id,
    page,
    taskProfile,
    taskResolution,
    since,
    until,
    current_attempt_number,
    order,
  }) => ({
    type: actionTypes.RetrievePendingAgentTasks,
    payload: {
      id,
      page,
      taskProfile,
      taskResolution,
      since,
      until,
      current_attempt_number,
      order,
    },
  }),
  setPendingAgentTasks: (tasks: TaskListModel) => ({
    type: actionTypes.SetPendingAgentTasks,
    payload: { tasks },
  }),
  setPendingAgentTasksStatus: (status: string) => ({
    type: actionTypes.SetPendingAgentTasksStatus,
    payload: { status },
  }),
  setGroups: (groups: Array<TaskGroupModel>) => ({
    type: actionTypes.SetGroups,
    payload: { groups },
  }),
  setStaffUsers: (staffUsers: Array<UserModel>) => ({
    type: actionTypes.SetStaffUsers,
    payload: { staffUsers },
  }),
  dispatchTask: (taskResolution?: number, taskProfile?: number) => ({
    type: actionTypes.DispatchTask,
    payload: {
      taskResolution,
      taskProfile,
    },
  }),
  requestTask: (taskId: number) => ({
    type: actionTypes.RequestTask,
    payload: { taskId },
  }),
  commentPendingTask: (taskComment: TaskCommentModel) => ({
    type: actionTypes.CommentPendingTask,
    payload: { taskComment: taskComment },
  }),
  commentSelectedTask: (taskComment: TaskCommentModel) => ({
    type: actionTypes.CommentSelectedTask,
    payload: { taskComment: taskComment },
  }),
  resolvePendingTask: (
    taskSetResolution: TaskSetResolutionModel,
    replaceAgent: boolean,
    isAlarm: boolean
  ) => ({
    type: actionTypes.ResolvePendingTask,
    payload: {
      taskSetResolution,
      replaceAgent,
      isAlarm,
    },
  }),
  resolveSelectedTask: (
    taskSetResolution: TaskSetResolutionModel,
    replaceAgent: boolean,
    isAlarm: boolean
  ) => ({
    type: actionTypes.ResolveSelectedTask,
    payload: {
      taskSetResolution,
      replaceAgent,
      isAlarm,
    },
  }),
  listGroups: () => ({
    type: actionTypes.listGroups,
  }),
  retrieveStaffUsers: () => ({
    type: actionTypes.RetrieveStaffUsers,
  }),
  setPendingTaskError: (error: string) => ({
    type: actionTypes.SetPendingTaskError,
    payload: { error },
  }),
  setSelectedTaskError: (error: string) => ({
    type: actionTypes.SetSelectedTaskError,
    payload: { error },
  }),
  setPendingTaskStatus: (status: TaskStatus) => ({
    type: actionTypes.SetPendingTaskStatus,
    payload: { status },
  }),
  setPendingTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetPendingTasksStatus,
    payload: { status },
  }),
  setPossibleTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetPossibleTasksStatus,
    payload: { status },
  }),
  setPendingCustomerTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetPendingCustomerTasksStatus,
    payload: { status },
  }),
  setNotReachableExpiredTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetNotReachableExpiredTasksStatus,
    payload: { status },
  }),
  setAlarmTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetAlarmTasksStatus,
    payload: { status },
  }),
  setSelectedTaskStatus: (status: TaskStatus) => ({
    type: actionTypes.SetSelectedTaskStatus,
    payload: { status },
  }),
  setPendingsPerAgentStatus: (status: TaskStatus) => ({
    type: actionTypes.SetPendingsPerAgentStatus,
    payload: { status },
  }),
  setClientTasksStatus: (status: TaskStatus) => ({
    type: actionTypes.SetClientTasksStatus,
    payload: { status },
  }),
  setPendingsPerAgent: (pendingsPerAgent: Array<PendingsModel>) => ({
    type: actionTypes.SetPendingsPerAgent,
    payload: { pendingsPerAgent },
  }),
  updatePendingTask: (task: TaskModel) => ({
    type: actionTypes.UpdatePendingTask,
    payload: { task },
  }),
};
