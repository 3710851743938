import { useEffect, useState } from 'react';
import DateRangeSelector from '../../../components/DateRangeSelector';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { listTopSales, SaleItem } from '../services/DashboardCRUD';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';
import getMultiSelectStrings from '../../../constants/getMultiSelectStrings';
import { DistributorModel, listDistributors } from '../../distributors';

export default function TopSalesReport() {
  const intl = useIntl();
  const initialSince = new Date(
    new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
  );
  const initialUntil = new Date(
    new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
  );
  const [since, setSince] = useState(initialSince);
  const [until, setUntil] = useState(initialUntil);
  const [distributors, setDistributors] = useState<Array<DistributorModel>>([]);
  const [selectedDistributors, setSelectedDistributors] = useState<
    Array<DistributorModel>
  >([]);
  const [generatingSalesReport, setGeneratingSalesReport] = useState(false);
  const [topSales, setTopSales] = useState<Array<SaleItem> | null>(null);
  const multiSelectStrings = getMultiSelectStrings(intl);

  async function fetchDistributors() {
    try {
      const { data: newDistributors } = await listDistributors('active');
      setDistributors(
        newDistributors.sort((a, b) => a.name.localeCompare(b.name))
      );
    } catch (error) {
      toast.error(
        intl.formatMessage({ id: 'businessDashboard.distributorsError' })
      );
      console.warn(error);
    }
  }

  async function generateSalesReport() {
    try {
      setGeneratingSalesReport(true);
      const { data: newTopSales } = await listTopSales({
        since: moment(since).format('YYYY-MM-DD'),
        until: moment(until).format('YYYY-MM-DD'),
        distributors: selectedDistributors.map((distributor) => distributor.id),
      });
      setTopSales(newTopSales?.Items || []);
    } catch (error) {
      toast.error(
        intl.formatMessage({ id: 'businessDashboard.topSalesReportError' })
      );
      console.warn(error);
    } finally {
      setGeneratingSalesReport(false);
    }
  }

  useEffect(() => {
    generateSalesReport();
  }, [since, until, selectedDistributors]);

  useEffect(() => {
    fetchDistributors();
  }, []);

  return (
    <>
      <h1 className="title mb-8">
        {intl.formatMessage({ id: 'businessDashboard.generateTopSalesReport' })}
      </h1>
      <div className="d-flex gap-4 align-items-end w-100">
        <DateRangeSelector
          initialSince={initialSince}
          initialUntil={initialUntil}
          onSelected={(since, until) => {
            setSince(since);
            setUntil(until);
          }}
          disableFutureDates={false}
        />
        <div className="form-group">
          <label className="form-label">
            {intl.formatMessage({
              id: 'businessDashboard.filterByDistributor',
            })}
          </label>
          <div className="w-400px mw-600px">
            <MultiSelect
              className="multi-select"
              overrideStrings={multiSelectStrings}
              labelledBy="Select"
              options={distributors.map((distributor) => ({
                label: distributor.name,
                value: distributor.id,
              }))}
              value={selectedDistributors.map((distributor) => ({
                label: distributor.name,
                value: distributor.id,
              }))}
              onChange={(someDistributors: any) => {
                setSelectedDistributors(
                  someDistributors.map((option: any) => {
                    return distributors.find(
                      (distributor) => distributor.id === option.value
                    );
                  })
                );
              }}
              valueRenderer={(selectedOptions) => {
                return selectedOptions.length > 0
                  ? selectedOptions.map((option) => option.label).join(', ')
                  : intl.formatMessage({
                      id: 'businessDashboard.filterByDistributor',
                    });
              }}
            />
          </div>
        </div>
        {generatingSalesReport && (
          <span className="spinner-border spinner-border-sm me-3" />
        )}
      </div>
      {Array.isArray(topSales) && (
        <section
          className="card shadow mt-8 p-10"
          style={{
            width: 'fit-content',
          }}
        >
          <h2 className="mb-8">
            {intl.formatMessage({ id: 'businessDashboard.topSales' })}
          </h2>
          {topSales.length > 0 ? (
            <table className="table table-striped table-hover table-bordered w-600px">
              <thead className="table-light fw-bold">
                <tr>
                  <th className="ps-4">
                    {intl.formatMessage({ id: 'businessDashboard.product' })}
                  </th>
                  <th className="pe-4">
                    {intl.formatMessage({ id: 'businessDashboard.units' })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: 'businessDashboard.totalWithoutTaxes',
                    })}
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {topSales.map((sale, index) => (
                  <tr key={index} className="table-row">
                    <td className="ps-4 mw-200px">{sale.product}</td>
                    <td className="text-end pe-10">{sale.total_units}</td>
                    <td className="text-end pe-11">
                      {intl.formatNumber(sale.total_price_wo_taxes, {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{intl.formatMessage({ id: 'businessDashboard.noTopSales' })}</p>
          )}
        </section>
      )}
    </>
  );
}
