import { FC } from 'react';
import { useIntl } from 'react-intl';

import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';
import { PageTitle } from '../../../_metronic/layout/core';
import GiantTable from '../../modules/tasks/components/GiantTable';

const GiantTableComponent: FC = () => {
  return (
    <>
      <GiantTable />
    </>
  );
};

const GiantTasksPage: FC = () => {
  const intl = useIntl();
  usePathProps();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'giantTasks.title' })}
      </PageTitle>
      <GiantTableComponent />
    </>
  );
};

export { GiantTasksPage };
