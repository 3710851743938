import { useState } from 'react';
import { useIntl } from 'react-intl';
import { generateReport } from '../services/InfluencersCRUD';
import { toast } from 'react-toastify';
import DateRangeSelector from '../../../components/DateRangeSelector';

export default function InfluencerStats() {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [since, setSince] = useState<Date>();
  const [until, setUntil] = useState<Date>();

  async function onGenerateReport() {
    try {
      setLoading(true);
      await generateReport({ since, until });
      toast.success(
        intl.formatMessage({ id: 'influencerStats.reportGenerated' })
      );
    } catch (error) {
      console.warn(error);
      toast.error(
        intl.formatMessage({ id: 'influencerStats.reportGenerationError' })
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <h1 className="mb-5">
        {intl.formatMessage({ id: 'influencerStats.title' })}
      </h1>
      <DateRangeSelector
        initialSince={since}
        initialUntil={until}
        onSelected={(since, until) => {
          setSince(since);
          setUntil(until);
        }}
      />
      <button
        className="btn btn-primary mt-8"
        onClick={onGenerateReport}
        disabled={loading}
      >
        {intl.formatMessage({ id: 'influencerStats.generate' })}
      </button>
    </div>
  );
}
